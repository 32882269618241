<template>
  <div class="form-field ocr-field">
    <div v-if="isLoading" class="loading">
      <span class="spinner"/>
      {{ processingMessage }}
    </div>

    <code-input-title
        v-bind="$props"
        :rid="rid"/>

    <upload-code-image
        v-bind="$props"
        :channel-id="uuid"
        :selected="selected"
        :endpoint="detectTextEndpoint"
        @message="onStatusChange"
        @loading="loading"
        @code-reset="codeReset"
        @code-select="codeSelect"
    />

    <code-input-hint
        v-bind="$props"
        :status="status"
    />

    <code-input
        v-if="requireUpc"
        codePlaceholder="UPC"
        codeInputName="workflow_data[upc]"
        :codeInputValue="upc"
        :status="status"
        :code="upc"
        :codes="[upc]"
        :message="upcMessage"
        rid="upc"
        @change="resetRecognized"
    />

    <div class="mb-2" />

    <code-input
        v-bind="$props"
        :status="status"
        :message="message"
        :codes="selected"
        :code="code"
        rid="rid"
        @change="resetRecognized"
    />

    <code-hidden-field
        v-if="codeHiddenName"
        v-bind="$props"
        :value="hiddenValue"
    />

    <input type="hidden" :name="recognizedInputName" value="true" v-if="recognized" />
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import {Statuses} from "./Statuses.model";
import Code from './Code.vue';
import Title from './Title.vue';
import Hint from './Hint.vue';
import UploadCodeImage from './UploadCodeImage.vue';
import CodeHiddenField from './CodeHiddenField.vue';

export default {
  props: {
    'codeInputLabel': {required: false, type: String},
    'codeInputName': {required: true, type: String},
    'codeHiddenName': {required: false, type: String},
    'codeImageName': {required: false, type: String},
    'codeInputValue': {required: false, type: String},
    'codePlaceholder': {required: false, type: String},
    'codePattern': {required: false, type: String},
    'codeMask': {required: false, type: String},
    'codePopupHelper': {required: false, type: Boolean},
    'codeTextHint': {required: true, type: String},
    'codeEmptyError': {required: true, type: String},
    'codeFormatError': {required: false, type: String},
    'photoLabel': {required: false, type: String},
    'photoSelectLabel': {required: false, type: String},
    'displayStatus': {required: false, type: String},
    'displayMessage': {required: false, type: String},
    'displayImage': {required: false, type: String},
    'processingMessage': {required: false, type: String},
    'sseEndpoint': {required: true, type: String},
    'detectTextEndpoint': {required: true, type: String},
    'requireUpc': {required: false, type: Boolean},
    'codeUpcValue': {required: false, type: String},
    'upcMessage': {required: false, type: String},
    'recognizedInputName': {required: true, type: String},
    'propRecognized': {required: false, type: Boolean}
  },

  data() {
    return {
      eventSource: null,
      codesMaxCount: 2,
      code: '',
      status: null,
      message: '',
      selected: [],
      isLoading: false,
      hiddenValue: false,
      upc: this.codeUpcValue,
      upcMessage: this.upcMessage,
      recognized: this.propRecognized
    };
  },

  components: {
    CodeInputTitle: Title,
    CodeInput: Code,
    CodeInputHint: Hint,
    UploadCodeImage,
    CodeHiddenField
  },

  created() {
    this.status = this.displayStatus || this.status;
    this.message = this.displayMessage || this.message;
  },

  mounted() {
    this.initSSERequest(this.sseEndpoint);
  },

  unmounted() {
    this.eventSource.close();
  },

  computed: {
    uuid() {
      return uuidv4();
    },
    rid() {
      return `input_${this.uuid}`;
    }
  },

  methods: {
    loading(isLoading) {
      this.isLoading = isLoading;
    },

    codeSelect(code) {
      this.code = code;

      let index = this.selected.indexOf(code);
      if (index === -1) {
        this.selected = [code, ...this.selected]
      } else {
        this.selected.splice(index, 1)
      }

      if (this.selected.length > this.codesMaxCount) {
        this.selected.splice(this.codesMaxCount);
      }
    },

    codeReset() {
      this.code = '';
      this.selected = [];
      this.upc = '';
      this.recognized = false;

      this.onStatusChange({
        status: null,
        message: null,
        upcMessage: null
      });
    },

    onStatusChange(status) {
      // true is everything is fine
      this.hiddenValue = status.status === Statuses.OK;
      this.message = status.message;
      this.upcMessage = status.upcMessage;
      this.status = status.status;
    },

    initSSERequest(endpoint) {
      const url = new URL(endpoint);
      url.searchParams.append('channel', this.uuid);
      this.eventSource = new EventSource(url.toString());

      this.eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const {status, message, serial_numbers, upcs, upc_message} = data;

        this.onStatusChange({
          status,
          message,
          upcMessage: upc_message
        });

        if (status === Statuses.OK && serial_numbers.length) {
          this.codeSelect(serial_numbers[0]);
        }

        this.upc = upcs && upcs[0]
        this.isLoading = false;
        this.recognized = status === Statuses.OK;
      };

      this.eventSource.onerror = (event) => {
        // todo: add some general handler if any
      };
    },

    resetRecognized() {
      this.recognized = false;
    }
  },
}
</script>

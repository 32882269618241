<template>
  <div>
    <input type="text"
        :name="codeInputName"
        :class="`input-${notifyClassName}`"
        :placeholder="codePlaceholder || ''"
        @blur.prevent="validateField"
        @change="$emit('change', $event.target.value)"
        :disabled="disabled"
        v-mask="inputMask"
        v-model="scode"
        :id="rid">

    <div :class="`form-field-notify form-field-${notifyClassName}`"
        v-if="message">{{message}}
    </div>
  </div>
</template>

<script>
  import {Statuses} from "./Statuses.model";

  export default {
    props: {
      rid: {required: true, type: String},
      code: {required: true, type: [String, Number]},
      codes: {required: true, type: Array},
      codePlaceholder: {required: false, type: String},
      codeInputName:{required: true, type: String},
      codeInputValue:{required: false, type: String},
      codePattern: {required: false, type: String},
      codeMask: {required: false, type: String},
      codeEmptyError: {required: true, type: String},
      codeFormatError: {required: false, type: String},
      status: {required: false, type: String},
      message: {required: false, type: String}
    },

    data() {
      return {
        scode: "",
        validationError: null,
        disabled: false,
        inputMask: this.codeMask || ''
      };
    },

    created() {
      if (this.codeInputValue) {
        this.scode = this.codeInputValue;
      } else {
        this.scode = this.codes.join(", ");
      }

      this.checkDisabledStatus();
    },

    watch: {
      codes(newVal) {
        this.scode = newVal.join(", ");
        this.checkDisabledStatus();
      },

      status() {
        this.checkDisabledStatus();
      }
    },

    computed: {
      notifyClassName() {
        const classes = {
          [Statuses.OK]: 'success',
          [Statuses.WARNING]: 'warning',
          [Statuses.ERROR]: 'error'
        };

        return this.status ? classes[this.status] : '';
      }
    },

    methods: {
      checkDisabledStatus() {
        this.disabled = !this.status;
      },

      validateField() {
        // todo: implement validation by pattern
        return true;
      }
    }
  };
</script>
